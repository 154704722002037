
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
      $cdnHost: 'https://websiteoss.ecoflow.com';
    
@import '@elem/styles/devices.scss';

.downloadSection {
    :global {
        background-color: #f8f8f8;
        .download-section {
            padding-top: 100px;
            padding-bottom: 80px;
            .title {
                font-size: 48px;
                text-align: center;
                color: #03060b;
                line-height: 76px;
                font-family: var(--ff-woff2-EB);
                html[site='cn'] & {
                    font-weight: 700;
                }
                html[site='jp'] & {
                    font-weight: 500;
                    font-size: 54px;
                }
            }
            .download-box {
                width: 1120px;
                height: 500px;
                margin: 80px auto 0;
                position: relative;
                border-radius: 30px;
                .radius-img {
                    border-radius: 30px;
                }
                .logo-box {
                    display: flex;
                    align-items: center;
                    padding-top: 80px;
                    margin-left: 80px;
                    html[site='fr'] & {
                        margin-left: 40px;
                    }
                    .logo {
                        width: 48px;
                        height: 48px;
                        position: relative;
                    }
                    .logo-text {
                        font-size: 40px;
                        margin-left: 20px;
                        color: #03060b;
                        line-height: 48px;
                        position: relative;
                        font-family: var(--ff-woff2-EB);
                        html[site='jp'] &,
                        html[site='cn'] & {
                            font-weight: 500;
                        }
                    }
                }
                .logo-desc {
                    position: relative;
                    margin-top: 20px;
                    font-size: 17px;
                    color: #757575;
                    line-height: 25px;
                    font-family: var(--ff-woff2-L);
                    margin-left: 80px;
                    width: 434px;
                    html[site='jp'] &,
                    html[site='cn'] & {
                        font-weight: 400;
                    }
                    html[site='fr'] & {
                        margin-left: 40px;
                    }
                }
                .download-list {
                    margin-top: 75px;
                    display: flex;
                    margin-left: 80px;
                    html[site='fr'] & {
                        margin-left: 40px;
                    }
                    .app-store {
                        .app-store__logo {
                            width: 140px;
                            height: 40px;
                            position: relative;
                        }
                        .text {
                            margin-top: 15px;
                            html[site='de'] & {
                                width: 140px;
                            }
                            html[site='fr'] & {
                                width: 140px;
                            }
                        }
                    }
                    .google-play {
                        margin-left: 36px;
                        html[site='cn'] & {
                            display: none;
                        }
                        .google-play__logo {
                            width: 140px;
                            height: 40px;
                            position: relative;
                        }
                        .text {
                            margin-top: 15px;
                            html[site='de'] & {
                                width: 150px;
                            }
                            html[site='fr'] & {
                                width: 150px;
                            }
                            html[site='cis'] & {
                                width: 150px;
                            }
                        }
                    }
                    .text {
                        font-size: 12px;
                        color: #757575;
                        line-height: 20px;
                        margin-top: 15px;
                        position: relative;
                        font-family: var(--ff-woff2-L);
                        html[site='jp'] &,
                        html[site='cn'] & {
                            font-weight: 400;
                        }
                    }
                }
                .download-link {
                    margin-top: 55px;
                    display: inline-flex;
                    align-items: center;
                    position: relative;
                    margin-left: 80px;
                    html[site='fr'] & {
                        margin-left: 40px;
                        margin-top: 30px;
                    }
                    html[site='cis'] & {
                        margin-top: 20px;
                    }
                    .link-text {
                        font-size: 14px;
                        color: #2164ff;
                        line-height: 25px;
                        font-family: var(--ff-woff2-L);
                        margin-left: 10px;
                        &:hover {
                            text-decoration: underline;
                        }
                        html[site='jp'] &,
                        html[site='cn'] & {
                            font-weight: 400;
                        }
                    }
                }
                .qr-code {
                    position: absolute;
                    left: 454px;
                    bottom: 69px;
                    html[site='cn'] & {
                        bottom: 104px;
                        left: 398px;
                    }
                    html[site='jp'] & {
                        text-align: center;
                        bottom: 90px;
                    }
                    html[site='de'] & {
                        bottom: 53px;
                    }
                    html[site='fr'] & {
                        bottom: 28px;
                    }
                    .qr-code__box {
                        position: relative;
                        width: 99px;
                        height: 99px;
                    }
                    .code-text {
                        font-size: 12px;
                        width: 99px;
                        color: #757575;
                        line-height: 14px;
                        margin-top: 8px;
                        font-family: var(--ff-woff2-L);
                        text-align: center;
                        html[site='cn'] & {
                            font-weight: 400;
                        }
                        html[site='jp'] & {
                            font-weight: 400;
                            width: 116px;
                        }
                        html[site='cis'] & {
                            width: 180px;
                            margin-left: -40px;
                        }
                    }
                }
            }
            .click-here {
                width: 1120px;
                margin: auto;
                padding-top: 30px;
                font-size: 17px;
                color: #757575;
                line-height: 25px;
                font-family: var(--ff-woff2-L);
                .color-ms1 {
                    color: #2164ff;
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }
    }
}
