$globalFonts: 'Helvetica Neue', Helvetica, 'Hiragino Sans GB', 'Hiragino Sans GB W3', 'Microsoft YaHei', '微软雅黑', Arial, simsun, sans-serif;

// 栅格断点
$breakpoints: (
    sm: 640px,
    xs: 768px,
    md: 1024px,
    lg: 1120px,
    lgm: 1180px,
    xl: 1120px,
    xxl: 1400px,
    mobile: 768px,
    ipad: 1024px,
    desktop: 1440px,
) !default;

/// Mixin to manage responsive breakpoints
/// @author Kitty Giraudel https://css-tricks.com/snippets/sass/mixin-manage-breakpoints/
/// @param {String} $breakpoint - Breakpoint name
/// @require $breakpoints
/// --------------------------------------
/// Usage:
/// .selector {
///     color: red;
///     @include maxWidth('sm') {
///         color: blue;
///     }
/// }
/// --------------------------------------
/// Result:
/// .selector {
///     color: red;
/// }
/// @media (max-width: 767px) {
///     .selector {
///         color: blue;
///     }
/// }
@mixin maxWidth($breakpoint) {
    // If the key exists in the map
    @if map-has-key($breakpoints, $breakpoint) {
        // Prints a media query based on the value
        @media (max-width: map-get($breakpoints, $breakpoint)) {
            @content;
        }
    }

    // If the key doesn't exist in the map
    @else {
        @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
          + "Available breakpoints are: #{map-keys($breakpoints)}.";
    }
}

@mixin mutipleImage($url, $suffix) {
    background-image: url(#{$url}@3x.#{$suffix});
    background-image: -webkit-image-set(url(#{$url}.#{$suffix}) 1x, url(#{$url}@2x.#{$suffix}) 2x, url(#{$url}@3x.#{$suffix}) 3x);
    background-image: image-set(url(#{$url}.#{$suffix}) 1x, url(#{$url}@2x.#{$suffix}) 2x, url(#{$url}@3x.#{$suffix}) 3x);
}

/**
* @desc 增加媒体查询区间检测范围
* @param {string} $startpoint - xs | md | lg | sm | xl | xxl
* @param {string} $endpoint - xs | md | lg | sm | xl | xxl
*
* @example:
*   // xxx.module.scss 文件下书写
*   @include between('md', 'xxl') {}
*/
@mixin between($startpoint, $endpoint) {
    @if map-has-key($breakpoints, $startpoint) and map-has-key($breakpoints, $endpoint) {
        @media (min-width: map-get($breakpoints, $startpoint)) and (max-width: map-get($breakpoints, $endpoint)) {
            @content;
        }
    } @else {
        @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
          + "Available breakpoints are: #{map-keys($breakpoints)}.";
    }
}

@mixin minWidth($breakpoint) {
    // If the key exists in the map
    @if map-has-key($breakpoints, $breakpoint) {
        // Prints a media query based on the value
        @media (min-width: map-get($breakpoints, $breakpoint)) {
            @content;
        }
    }

    // If the key doesn't exist in the map
    @else {
        @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
          + "Available breakpoints are: #{map-keys($breakpoints)}.";
    }
}
