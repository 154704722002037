
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
      $cdnHost: 'https://websiteoss.ecoflow.com';
    
@import '@elem/styles/devices.scss';

.downloadSection {
    :global {
        background-color: #f8f8f8;
        padding-top: 0.3rem;
        .download-box {
            width: 3.26rem;
            height: 5.56rem;
            background: #ffffff;
            border-radius: 0.16rem;
            position: relative;
            margin: auto;
            .title-box {
                display: flex;
                align-items: center;
                padding-top: 0.19rem;
                justify-content: center;
                .logo {
                    position: relative;
                    width: 0.38rem;
                    height: 0.38rem;
                }
                .logo-text {
                    font-size: 0.24rem;
                    font-family: var(--ff-woff2-EB);
                    color: #03060b;
                    line-height: 0.34rem;
                    margin-left: 0.1rem;
                    html[site='cn'] & {
                        font-weight: 600;
                    }
                    html[site='jp'] & {
                        font-weight: 500;
                    }
                    html[site='fr'] & {
                        font-size: 0.2rem;
                        line-height: 0.28rem;
                    }
                }
            }
            .desc {
                margin: 0.13rem 0.2rem 0;
                font-size: 0.15rem;
                color: #919191;
                line-height: 0.23rem;
                text-align: center;
                font-family: var(--ff-woff2-M);
                html[site='jp'] &,
                html[site='cn'] & {
                    font-weight: 400;
                }
            }
            .store-img {
                width: 1.4rem;
                height: 0.4rem;
                position: relative;
                margin: 0.2rem auto 0;
            }
            .store-desc {
                color: #919191;
                line-height: 0.2rem;
                font-size: 0.12rem;
                font-family: var(--ff-woff2-M);
                text-align: center;
                margin-top: 0.19rem;
                html[site='jp'] &,
                html[site='cn'] & {
                    font-weight: 400;
                }
            }
            .download-link {
                display: flex;
                justify-content: center;
                margin-top: 0.1rem;
                html[site='jp'] & {
                    width: 2rem;
                    margin: 0.1rem auto 0;
                    align-items: center;
                    svg {
                        flex-shrink: 0;
                    }
                }
                .link-text {
                    font-size: 0.14rem;
                    color: #03060b;
                    line-height: 0.18rem;
                    font-family: var(--ff-woff2-L);
                    margin-left: 0.1rem;
                    html[site='jp'] &,
                    html[site='cn'] & {
                        font-weight: 400;
                    }
                }
            }
            .bottom-img {
                position: absolute;
                width: 3.26rem;
                height: 2.26rem;
                bottom: 0;
                border-bottom-left-radius: 0.16rem;
                border-bottom-right-radius: 0.16rem;
                .fullImgs {
                    border-bottom-left-radius: 0.16rem;
                    border-bottom-right-radius: 0.16rem;
                }
            }
        }
        .click-here {
            width: 2.85rem;
            margin-left: 0.25rem;
            padding-top: 0.1rem;
            padding-bottom: 0.2rem;
            font-size: 0.12rem;
            color: #919191;
            line-height: 0.2rem;
            font-family: var(--ff-woff2-M);
            html[site='jp'] &,
            html[site='cn'] & {
                font-weight: 400;
            }
            .color-ms1 {
                color: #2164ff;
                text-decoration: underline;
            }
        }
        .google-box {
            html[site='cn'] & {
                display: none;
            }
        }
        .google-text {
            html[site='cn'] & {
                display: none;
            }
        }
    }
}
