
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
      $cdnHost: 'https://websiteoss.ecoflow.com';
    
.secondSeriesInApp {
    :global {
        background-color: #f8f8f8;
        height: 200vh;
        .second-series {
            padding-top: 0.4rem;
            position: sticky;
            position: -webkit-sticky;
            top: 0.6rem;
            .title {
                width: 2.85rem;
                font-family: var(--font-family-ExtraBold);
                font-size: 0.24rem;
                color: #03060b;
                margin: auto;
                text-align: center;
                line-height: 0.34rem;
                html[site='jp'] & {
                    font-weight: 700;
                    white-space: pre-line;
                }
                html[site='cn'] & {
                    font-weight: 700;
                }
            }
            .show-box {
                width: 100%;
                overflow: hidden;
                .img-box {
                    position: relative;
                    left: 50%;
                    margin-top: 0.4rem;
                    transform: translateX(-50%);
                    width: 6.18rem;
                    height: 5.12rem;
                    transform-origin: top center;
                }
            }
            .move-box {
                position: relative;
                height: 2.89rem;
                .swiper-box {
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 3.26rem;
                    margin: auto;
                    .swiper-list {
                        height: 5.31rem;
                        .swiper-item {
                            height: 4.96rem;
                            background: #ffffff;
                            border-radius: 0.16rem;
                            position: relative;
                            html[site='de'] & {
                                height: 5.56rem;
                            }
                            html[site='fr'] & {
                                height: 5.76rem;
                            }
                            .title {
                                font-size: 0.18rem;
                                color: #03060b;
                                line-height: 0.22rem;
                                font-family: var(--font-family-ExtraBold);
                                padding-top: 0.2rem;
                                html[site='cn'] & {
                                    font-weight: 700;
                                }
                                html[site='jp'] & {
                                    font-weight: 500;
                                    white-space: pre-line;
                                }
                                html[site='cis'] & {
                                    padding-top: 0.1rem;
                                }
                            }
                            .desc {
                                margin: 0.12rem 0.2rem 0;
                                font-size: 0.14rem;
                                font-family: var(--font-family-Bold);
                                color: #666;
                                line-height: 0.22rem;
                                text-align: center;
                                html[site='cn'] & {
                                    font-weight: 600;
                                }
                                html[site='jp'] & {
                                    font-weight: 400;
                                    font-family: var(--font-family-SemiBold);
                                }
                                html[site='cis'] & {
                                    line-height: 0.18rem;
                                    margin-top: .08rem;
                                }
                            }
                            .swiper-img {
                                position: absolute;
                                width: 3.26rem;
                                height: 2.92rem;
                                bottom: 0;
                            }
                        }
                    }
                    .swiper-pagination-bullet {
                        width: 14px;
                        height: 2px;
                        border-radius: 2px;
                    }
                }
            }
        }
    }
}
