
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
      $cdnHost: 'https://websiteoss.ecoflow.com';
    
.firstSeriesInAppH5 {
    width: 100%;
    background: #fff;

    :global {
        .first-series {
            padding: 0.8rem 0.42rem 0.25rem;
            html[site='jp'] & {
                padding-left: 0.25rem;
                padding-right: 0.25rem;
            }
        }
        .top_title {
            padding-bottom: 0.6rem;
            font-size: 0.28rem;
            font-family: var(--font-family-ExtraBold);
            color: #03060b;
            line-height: 0.36rem;
            text-align: center;
            html[site='jp'] & {
                font-weight: 500;
                white-space: pre-line;
            }
            html[site='cn'] & {
                font-weight: 700;
                white-space: pre-line;
            }
        }
        .scoll_item {
            text-align: center;
            .title {
                font-size: 0.24rem;
                font-family: var(--font-family-ExtraBold);
                color: #03060b;
                line-height: 0.34rem;
                html[site='jp'] & {
                    font-weight: 700;
                    font-size: 0.23rem;
                    white-space: pre-line;
                }
                html[site='cn'] & {
                    font-weight: 700;
                    white-space: pre-line;
                }
            }
            .desc {
                margin-top: 0.08rem;
                font-size: 0.14rem;
                font-family: var(--font-family-Bold);
                color: #666666;
                line-height: 0.22rem;
                html[site='jp'] & {
                    font-family: var(--font-family-SemiBold);
                    font-size: 0.13rem;
                    font-weight: 400;
                    white-space: pre-line;
                }
                html[site='cn'] & {
                    font-weight: 600;
                }
            }
            .icon_box {
                margin-top: 0.1rem;
                display: flex;
                justify-content: space-between;
                .icon_item {
                    flex: 1;
                    font-size: 0.12rem;
                    font-family: var(--font-family-Bold);
                    color: #03060b;
                    line-height: 0.16rem;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    white-space: nowrap;
                    html[site='jp'] & {
                        font-weight: 600;
                        justify-content: flex-start;
                        .icon-text {
                            display: block;
                            width: 0.65rem;
                            text-align: center;
                            white-space: pre-line;
                        }
                    }
                    html[site='cn'] & {
                        font-weight: 600;
                    }
                    html[site='de'] & {
                        .icon-text {
                            display: block;
                            width: 0.6rem;
                            white-space: pre-line;
                        }
                        .icon-text2 {
                            width: 1.5rem;
                        }
                    }
                    html[site='fr'] & {
                        .icon-text {
                            display: block;
                            width: 0.6rem;
                            white-space: pre-line;
                        }
                        .icon-text2 {
                            width: 1.5rem;
                        }
                    }
                }
            }
        }

        .img_box {
            position: relative;
            width: 2.91rem;
            height: 4.08rem;
            margin: 0.3rem auto 0;
        }
    }
}
