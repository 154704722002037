
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
      $cdnHost: 'https://websiteoss.ecoflow.com';
    
.overview {
    :global {
        .section2 {
            padding-top: 180px;
        }
        .section3 {
            .section3-bg-header {
                height: 280px;
                background-color: #000;
            }
        }
    }
}
