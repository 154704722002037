
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
      $cdnHost: 'https://websiteoss.ecoflow.com';
    
.customSpin {
    :global(.ant-spin-dot-item) {
        background-color: #8c8c8c;
    }
}
