
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
      $cdnHost: 'https://websiteoss.ecoflow.com';
    
@import '@/styles/variable.scss';
@import '@elem/styles/devices.scss';

.secondScreen {
    position: relative;
    background-color: #000;
    html[site='cn'] & {
        font-weight: 400;
    }
    :global {
        .textBox {
            width: 100%;
            text-align: center;
            margin-top: 0.6rem;
            margin-bottom: 0.3rem;
            color: #ffffff;
            .title {
                font-size: 0.16rem;
                font-family: var(--ff-woff2-EB);
                line-height: 0.2rem;
            }
            .desc {
                font-size: 0.28rem;
                font-family: var(--ff-woff2-EM);
                line-height: 0.36rem;
                width: 3.25rem;
                margin: 0 auto;
                margin-top: 0.06rem;
                text-align: center;
                html[site='cn'] & {
                    font-weight: 700;
                }
            }
        }
        .swiper_box {
            position: relative;
            margin-bottom: 0.47rem;
            .swiper_controller {
                .swiper-slide {
                    background: #151515;
                    border-radius: 0.16rem;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    width: 3.26rem;
                    height: 4rem;
                    html[site='de'] &,
                    html[site='fr'] & {
                        height: 4.37rem !important;
                    }
                    .textBox {
                        width: 2.66rem;
                        margin: 0 auto;
                        margin-top: 0.2rem;
                        text-align: left;
                        .title {
                            font-size: 0.2rem;
                            font-family: var(--ff-woff2-EB);
                            color: #ffffff;
                            line-height: 0.24rem;
                            html[site='cn'] & {
                                font-weight: 700;
                            }
                        }
                        .desc2 {
                            font-size: 0.14rem;
                            margin-top: 0.1rem;
                            font-family: var(--ff-woff2-EM);
                            color: #999999;
                            line-height: 0.2rem;
                        }
                    }
                    .imgBox {
                        width: 2.2rem;
                        height: 2.77rem;
                        margin: 0 auto;
                    }
                }
                .swiper_item:first-child {
                    margin-left: calc(50vw - 1.63rem);
                }
            }
            .paginations {
                margin: 0 auto;
                padding-top: 0.2rem;
                width: 100%;
                text-align: center;
                .pagination-item {
                    width: 0.08rem;
                    height: 0.08rem;
                    background: rgba(255, 255, 255, 0.5);
                    border-radius: 50%;
                    display: inline-block;
                    margin: 0 0.04rem;
                    &.active {
                        background: #fff;
                    }
                }
            }
            .arrowIcon {
                position: absolute;
                display: inline-block;
                top: calc(50% - 0.22rem);
                width: 0.44rem;
                height: 0.44rem;
                transform: translateY(-50%);
                backdrop-filter: blur(4px);
                -webkit-backdrop-filter: blur(4px);
                z-index: 100;
                cursor: pointer;
                .icon {
                    font-size: 24px;
                    color: #fff;
                    margin: auto;
                }
            }
            .prevIcon {
                left: 0.1rem;
                z-index: 100;
                border-radius: 26px;
                &:hover {
                    background-color: rgba(18, 18, 18, 0.1);
                }
                .icon {
                    transform: rotate(90deg);
                }
            }
            .nextIcon {
                right: 0.1rem;
                border-radius: 26px;
                &:hover {
                    background-color: rgba(18, 18, 18, 0.1);
                }
                .icon {
                    transform: rotate(-90deg);
                }
            }
        }
    }
}
