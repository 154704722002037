
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
      $cdnHost: 'https://websiteoss.ecoflow.com';
    
.iotComp {
    :global {
        .iot-comp {
            width: 687px;
            margin-left: 56px;
            padding-top: 30px;
            padding-bottom: 60px;
            .tab-box {
                height: 40px;
                padding: 0 60px;
                border-bottom: 1px solid #ebe9e9;
                display: flex;
                justify-content: space-between;
                html[site='de'] & {
                    padding: 0 40px;
                }
                html[site='fr'] & {
                    padding: 0 40px;
                }
                .item-box {
                    display: flex;
                    position: relative;
                    flex-shrink: 0;
                    top: 1px;
                    .tab-item {
                        cursor: pointer;
                        flex-shrink: 0;
                        font-size: 17px;
                        font-family: var(--font-family-SemiBold);
                        color: #6d7278;
                        line-height: 25px;
                        margin-left: 4px;
                        html[site='jp'] &,
                        html[site='cn'] & {
                            font-weight: 500;
                        }
                    }
                    html[site='de'] & {
                        .tab-item:first-of-type {
                            min-width: 100px;
                        }
                    }
                    svg {
                        margin-top: -2px;
                    }
                    &.active {
                        border-bottom: 1px solid #03060b;
                        color: #03060b;
                        .tab-item {
                            color: #03060b;
                        }
                    }
                }
            }
            .box {
                margin-top: 50px;
                .desc {
                    font-size: 14px;
                    font-family: var(--font-family-Regular);
                    color: #35404e;
                    line-height: 22px;
                    html[site='jp'] &,
                    html[site='cn'] & {
                        font-weight: 400;
                    }
                }
                .video-box {
                    margin-top: 32px;
                    width: 686px;
                    height: 386px;
                    position: relative;
                    border-radius: 20px;
                    .video {
                        border-radius: 20px;
                        position: relative;
                        z-index: 1;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }
    }
}
