
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
      $cdnHost: 'https://websiteoss.ecoflow.com';
    
@import '@/styles/variable.scss';
@import '@elem/styles/devices.scss';

.firstScreen {
    height: calc(100vh + 365px);

    :global {
        .video_box {
            position: sticky;
            top: 0;
            left: 0;
            width: 100%;
            height: 100vh;
            margin-top: -100px;
            z-index: 1;

            .video_position {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                overflow: hidden;
                position: relative;
                video {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        .down_box {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100vh;
            opacity: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            z-index: 100;
            will-change: opacity, transform;

            .down_box_title {
                font-size: 64px;
                font-family: var(--ff-woff2-EB);
                color: #fff;
                line-height: 72px;
                html[site='cn'] & {
                    width: 1040px;
                    text-align: center;
                    font-weight: 700;
                }
                html[site='jp'] & {
                    width: 1040px;
                    text-align: center;
                    font-size: 60px;
                    line-height: 66px;
                }
            }
            .down_box_content {
                margin: 24px auto 0;
                font-size: 22px;
                font-family: var(--ff-woff2-EB);
                color: #999;
                line-height: 36px;
                text-align: center;
                p {
                    width: 728px;
                    text-align: center;
                    margin: auto;
                    html[site='cn'] & {
                        width: 780px;
                    }
                }
                html[site='jp'] & {
                    font-size: 20px;
                    line-height: 28.5px;
                    font-family: var(--ff-woff2-L);
                    color: #fff;
                }
                html[site='cn'] & {
                    font-weight: 600;
                    white-space: pre-line;
                }

                .down_box_link {
                    width: 860px;
                    margin: 19px auto 0;
                    display: flex;
                    justify-content: center;

                    a {
                        display: inline-block;
                        font-size: 17px;
                        font-family: var(--ff-woff2-L);
                        color: #2164ff;
                        line-height: 25px;
                        margin: 0 30px;
                        &:hover {
                            text-decoration: underline;
                        }
                        html[site='jp'] &,
                        html[site='cn'] & {
                            font-weight: 400;
                        }
                    }
                }

                .down_box_btn {
                    display: flex;
                    justify-content: center;

                    .play-item {
                        margin: 40px 20px;
                        width: 210px;
                        height: 60px;
                        background: #1b1b1b;
                        border-radius: 10px;
                    }
                    .hide-cn {
                        html[site='cn'] & {
                            display: none;
                        }
                    }
                }
            }
        }

        .down_mask {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100vh;
            background: #000;
            z-index: 99;
        }
    }
}
