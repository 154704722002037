
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
      $cdnHost: 'https://websiteoss.ecoflow.com';
    
@import '@/styles/variable.scss';
@import '@elem/styles/devices.scss';

.secondScreen {
    position: relative;
    background-color: #000;
    html[site='cn'] & {
        font-weight: 400;
    }

    :global {
        .textBox {
            width: 100%;
            text-align: center;
            margin-bottom: 60px;
            color: #ffffff;
            .title {
                font-size: 28px;
                font-family: var(--ff-woff2-M);
                line-height: 34px;
            }
            .desc {
                font-size: 63px;
                font-family: var(--ff-woff2-EM);
                line-height: 72px;
                width: 980px;
                margin: 0 auto;
                margin-top: 12px;
                text-align: center;
                html[site='cn'] & {
                    font-weight: 700;
                }
            }
        }
        .sliderBox {
            position: relative;
            margin-bottom: 240px;
            html[site='us'] &,
            html[site='uk'] &,
            html[site='eu'] &,
            html[site='au'] &,
            html[site='ca'] &,
            html[site='de'] &,
            html[site='jp'] &,
            html[site='fr'] & {
                margin-bottom: 120px;
            }
            .cardSliderWrapper {
                overflow: hidden;
                .arrowIcon {
                    position: absolute;
                    display: inline-block;
                    top: 50%;
                    transform: translateY(-50%);
                    backdrop-filter: blur(4px);
                    -webkit-backdrop-filter: blur(4px);
                    cursor: pointer;
                    .icon {
                        font-size: 24px;
                        color: #fff;
                        margin: auto;
                    }
                }
                .prevIcon {
                    z-index: 100;
                    border-radius: 26px;
                    &:hover {
                        background-color: rgba(18, 18, 18, 0.1);
                    }
                    .icon {
                        transform: rotate(90deg);
                    }
                }
                .nextIcon {
                    border-radius: 26px;
                    &:hover {
                        background-color: rgba(18, 18, 18, 0.1);
                    }
                    .icon {
                        transform: rotate(-90deg);
                    }
                }
                .sliderContent {
                    position: relative;
                    .listBox {
                        display: inline-flex;
                        overflow: hidden;
                        .list {
                            background: #151515;
                            border-radius: 24px;
                            display: inline-flex;
                            flex-direction: column;
                            justify-content: space-between;
                            html[site='de'] &,
                            html[site='fr'] & {
                                height: 615px !important;
                            }
                            .textBox {
                                width: 420px;
                                margin: 0 auto;
                                margin-top: 40px;
                                text-align: left;
                                .title {
                                    font-size: 28px;
                                    font-family: var(--ff-woff2-EB);
                                    color: #ffffff;
                                    line-height: 32px;
                                    html[site='cn'] & {
                                        font-weight: 700;
                                    }
                                }
                                .desc2 {
                                    font-size: 19px;
                                    margin-top: 16px;
                                    font-family: var(--ff-woff2-EM);
                                    color: #999999;
                                    line-height: 27px;
                                    html[site='cn'] & {
                                        font-weight: 500;
                                    }
                                }
                            }
                            .imgBox {
                                margin: 0 auto;
                            }
                        }
                        .list > div {
                            vertical-align: bottom;
                        }
                    }
                }
            }
        }
    }
}
