
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
      $cdnHost: 'https://websiteoss.ecoflow.com';
    
.efPicture {
    :global {
        position: relative;
        width: 100%;
        height: 100%;
        text-align: center;
        .picture{
            position: relative;
            display: block;
            width: 100%;
            height: 100%;
            .picture-img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
    }
}
