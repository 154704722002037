
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
      $cdnHost: 'https://websiteoss.ecoflow.com';
    
@import "@/styles/variable.scss";

.appDownload {
    :global {
        .down_nav {
            position: -webkit-sticky;
            /* Safari */
            position: sticky;
            top: 0;
            z-index: 999;
        }
        .detail-nav {
            border-bottom: 1px solid #edebeb !important;
            .rightLists {
							html[site='cn']{
								li span{
									@include ef-font(Light);
								}
							}
            }
        }
        .detail-nav.black {
            border-bottom: 1px solid rgba(235, 233, 233, 0.2) !important;
        }
        .ecoflow-frame-main {
            min-height: calc(100vh - 632px) !important;
        }
        .footers {
            background: #f8f8f8;
            min-height: 570px;
            border-top: 1px solid #ededed;
        }
    }
}
