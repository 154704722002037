
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
      $cdnHost: 'https://websiteoss.ecoflow.com';
    
.bluetooth {
    :global {
        .bluetooth {
            margin-left: 56px;
            .bluetooth-title {
                font-size: 20px;
                font-family: var(--font-family-SemiBold);
                color: #03060b;
                line-height: 28px;
                html[site='jp'] &,
                html[site='cn'] & {
                    font-weight: 500;
                }
            }
            .common-desc {
                font-size: 14px;
                font-family: var(--font-family-Regular);
                color: #35404e;
                line-height: 22px;
                html[site='jp'] &,
                html[site='cn'] & {
                    font-weight: 400;
                }
            }
            .desc1 {
                margin-top: 12px;
            }
            .desc2 {
                margin-top: 22px;
            }
            .step-box {
                .step-item {
                    padding-bottom: 30.28px;
                    margin-top: 27.82px;
                    padding-left: 34.56px;
                    border-bottom: 1px solid #ededed;
                    display: flex;
                    align-items: center;
                    .step-img {
                        width: 254px;
                        height: 490px;
                        position: relative;
                    }
                    .step-desc {
                        width: 350px;
                        margin-left: 40.08px;
                        font-size: 14px;
                        color: #35404e;
                        line-height: 22px;
                        font-family: var(--font-family-Regular);
                        html[site='jp'] &,
                        html[site='cn'] & {
                            font-weight: 400;
                        }
                    }
                }
            }
            .note-box {
                margin-top: 40px;
                margin-bottom: 180px;
                .note {
                    font-size: 14px;
                    font-family: var(--font-family-SemiBold);
                    color: #35404e;
                    line-height: 22px;
                    html[site='jp'] &,
                    html[site='cn'] & {
                        font-weight: 500;
                    }
                }
                .note-desc {
                    white-space: pre-line;
                    color: #757575;
                    font-size: 12px;
                    line-height: 20px;
                    font-family: var(--font-family-Regular);
                    html[site='jp'] &,
                    html[site='cn'] & {
                        font-weight: 400;
                    }
                }
            }
        }
    }
}
