
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
      $cdnHost: 'https://websiteoss.ecoflow.com';
    
.EFFigure {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: auto 100%;
    height: 100%;
    width: 100%;
    display: block;
}
